@import "../../assets/styles/variables.scss";

.form {
  position: absolute;
  z-index: 10;
  top: 50px;
  right: 50px;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  background-color: rgba(247, 247, 247, 0.9);
  input,
  textarea {
    width: 100%;
  }
  p {
    text-align: left;
    color: #222;
  }
  div {
    width: 100%;
  }
}

.nav__item {
  color: #fff;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: all 0.6s ease-in-out;
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 40px;
  min-width: 41px;
  &:hover {
    transition: all 0.3s ease-in-out;
    background: transparent;
  }
}

.bl {
  color: #333;
  border: thin solid $yellow;
  &:hover {
    color: #333;
  }
}
.bl__active {
  color: #fff;
  background: $yellow;
  &:hover {
    color: #fff;
    background: $yellow;
  }
}

.rv {
  color: #333;
  border: thin solid $green;
  &:hover {
    color: #333;
  }
}
.rv__active {
  color: #fff;
  background: $green;
  &:hover {
    color: #fff;
    background: $green;
  }
}

.pr {
  color: #333;
  border: thin solid $blue;
  &:hover {
    color: #333;
  }
}
.pr__active {
  color: #fff;
  background: $blue;
  &:hover {
    color: #fff;
    background: $blue;
  }
}

.co {
  color: #333;
  border: thin solid $purple;
  &:hover {
    color: #333;
  }
}
.co__active {
  color: #fff;
  background: $purple;
  &:hover {
    color: #fff;
    background: $purple;
  }
}

.x {
  color: #333;
  border: thin solid $red;
  &:hover {
    color: #333;
  }
}
.x__active {
  color: #fff;
  background: $red;
  &:hover {
    color: #fff;
    background: $red;
  }
}
