@import "../../assets/styles/variables.scss";
.nav {
  width: 73px;
  height: 100vh;
  background: #091d3a;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-direction: column;
}
.item {
  opacity: 0.25;
  transition: all 0.5s ease-in-out;
  display: block;
  padding: 10px;
  &:hover {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}

.nav__item {
  display: block;
  color: #fff;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: all 0.6s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: transparent;
  }
}

.bl {
  background: $yellow;
  border: thin solid $yellow;
  &:hover {
    color: $yellow;
  }
}

.rv {
  background: $green;
  border: thin solid $green;
  &:hover {
    color: $green;
  }
}
.pr {
  background: $blue;
  border: thin solid $blue;
  &:hover {
    color: $blue;
  }
}
.co {
  background: $purple;
  border: thin solid $purple;
  &:hover {
    color: $purple;
  }
}
.x {
  background: $red;
  border: thin solid $red;
  &:hover {
    color: $red;
  }
}
