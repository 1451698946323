@import "../../assets/styles/variables.scss";

.subnav {
  position: fixed;
  left: 75px;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  padding: 25px;
  overflow-y: auto;
  ul {
    list-style: none;
    padding: 0;
    li {
      border-bottom: thin solid rgba(151, 151, 151, 0.25);
      padding: 10px 20px;
      padding-bottom: 0;
      position: relative;
      &:hover {
        background: rgba(151, 151, 151, 0.1);
        cursor: pointer;
      }
      a {
        right: 20px;
        position: absolute;
        &:hover {
          color: #222;
        }
      }
    }
  }

  a {
    color: #fc5542;
    text-decoration: none;
    position: absolute;
    top: 20px;
    right: 30px;
  }
}
.promotion__wrapper {
  height: 70px;
}
.demoteBtn {
  position: static !important;
  background: $blue;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.8s all ease-in-out;
  &:hover {
    transition: 0.3s all ease-in-out;
    background: lighten($blue, 4%);
  }
}
.promotionBtn {
  position: static !important;
  background: $red;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.8s all ease-in-out;
  margin-left: 5px;
  &:hover {
    transition: 0.3s all ease-in-out;
    background: lighten($red, 4%);
  }
}
.records {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  display: flex;
  align-items: center;
  padding-left: 20px;
  a {
    position: absolute;
    top: 20px;
    right: 30px;
  }
}
.title {
  flex-direction: row;
  h1 {
    display: contents;
  }
  p {
    margin-top: 1.5em;
  }
}
.bl {
  background: $purple;
  padding: 10px;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
}
