.input {
  border: none;
  border-bottom: thin solid rgba(151, 151, 151, 1);
  background: transparent;
  padding: 10px;
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &::placeholder {
    font-style: italic !important;
  }
}
.form__group {
  padding: 30px 0;
  position: relative;
  label {
    position: absolute;
    text-transform: uppercase;
    font-weight: 600;
    font-size: small;
    top: 0;
    left: 0;
  }
}
