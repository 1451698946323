.subnav {
  position: fixed;
  left: 75px;
  width: 100%;
  max-width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 25px;
  padding-bottom: 100px;
  overflow-y: auto;
  z-index: 100;
  img {
    width: 100%;
  }
  .thumb {
    text-align: center;
    margin-top: 50px;
    h5 {
      margin-top: 30px;
    }
    p {
      margin: auto;
    }
  }
  p {
    max-width: 100%;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    li {
      border-bottom: thin solid rgba(151, 151, 151, 0.25);
      padding: 10px 0;
      padding-bottom: 0;
      position: relative;
      a {
        right: 0;
        position: absolute;
        &:hover {
          color: #222;
        }
      }
    }
  }

  a {
    color: #fc5542;
    text-decoration: none;
  }
}
.closeBtn {
  position: absolute;
  right: 30px;
  top: 20px;
}
.contact__us__btb {
  margin-top: 70px;
}
